/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @next/next/no-img-element */
import { useState, useEffect, useCallback } from "react";
import { NextRouter, useRouter } from "next/router";
import Link from "next/link";
import cn from "classnames";
import { GetServerSideProps } from "next";
import cookies from "next-cookies";

import useEventListener from "~web/utils/useEventListener";
import NextHead from "~web/components/NextHead";

export default function Landing() {
  const { push }: NextRouter = useRouter();
  const [runAnimation, toggleRunAnimation] = useState<boolean>(false);
  const [coords, setCoords] = useState<{
    x: number;
    y: number;
    scrollX?: number;
    scrollY?: number;
    offset: number;
  }>({ x: 0, y: 0, offset: 0 });
  const [offset, setOffset] = useState<any>(false);

  useEffect(() => {
    setOffset({
      ...offset,
      id_2:
        document?.getElementById("id_2")?.offsetTop ||
        0 - window.innerHeight / 2,
      id_3:
        document?.getElementById("id_3")?.offsetTop ||
        0 - window.innerHeight / 2,
      id_4:
        document?.getElementById("id_4")?.offsetTop ||
        0 - window.innerHeight / 2,
    });
  }, []);

  const handler = useCallback(
    ({ pageX, pageY, scrollX, scrollY }) =>
      setCoords((prev) => ({ ...prev, x: pageX, y: pageY, scrollX, scrollY })),
    [setCoords]
  );

  const scrollHandler = useCallback(
    () => setCoords((prev) => ({ ...prev, offset: window.pageYOffset })),
    [setCoords]
  );

  useEventListener("mousemove", handler);
  useEventListener("scroll", scrollHandler);

  return (
    <>
      <NextHead title="MEM Q&A — The Member-Driven Knowledgebase" />

      <div className="landing">
        <div
          className="landing-hover-effect"
          style={{
            top: coords.y - 75,
            left: coords.x - 75,
          }}
        />
        <Header />
        <div className="landing-splash max-vh max-w col-c-c">
          <div
            className="landing-splash-img-container row-c-c"
            style={{ backgroundImage: "url(/splash_illo.png)" }}
          >
            <div className="max-w max-1080">
              <div
                style={{ maxWidth: 640, position: "relative", zIndex: 10 }}
                className="col max-1080 mobile-shift"
              >
                <h1 className="align-center">
                  The Member-Driven Knowledgebase
                </h1>
                <div className="space-v" />
                <div className="space-v" />
                <div className="space-v" />
                <div className="space-v" />
                <h4 className="align-center">
                  Ask questions, find answers, build community.
                </h4>
                <div className="space-v" />
                <div className="space-v" />
                <div className="space-v" />
                <div className="space-v" />
                <div className="row-fs-c mobile-shift">
                  <button
                    className="colors"
                    style={{ height: 42 }}
                    onClick={() => push("/community/mem-protocol")}
                  >
                    Launch App →
                  </button>
                  <div className="space-v" />
                  <div className="space-h" />
                  <SignUpForm />
                </div>
              </div>
            </div>
          </div>
          <div style={{ height: "5%", bottom: "-5%" }} />
        </div>
        <div id="breakpoint-1" />
        <div className="landing-card max-1080 radial-bg col pad-64">
          <h2>Find your communities.</h2>
          <div className="space-v" />
          <div className="space-v" />
          <div className="space-v" />
          <div className="space-v" />
          <div className="max-w landing-grid">
            {[
              ["Commonwealth", "7,151+", "/cw_logo.svg"],
              ["Injective", "10,304+", "/injective_logo.svg"],
              ["Mem Protocol", "5,102+", "/logo_neon.png"],
            ].map(([title, num, logo], i) => (
              <div className="row-fs-c" key={`grid-${i}`}>
                <img className="find-img-logo" src={logo} />
                <div className="img-placeholder" />
                <div className="space-h" />
                <div className="space-h" />
                <div className="col">
                  {title}
                  <p className="col" style={{ fontSize: 16 }}>
                    {num} members
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className="space-v" />
          <div className="space-v" />
          <div className="space-v" />
          <div className="space-v" />
          <div className="row-fe-c mobile-shift max-w">
            <h4>Don&#39;t see yours?</h4>
            <div className="space-h" />
            <div className="space-h" />
            <div className="space-v show-on-mobile" />
            <div className="space-v show-on-mobile" />
            <a href="https://memprotocol.typeform.com/onboarding">
              <button className="primary">Reach out to get on Mem →</button>
            </a>
          </div>
        </div>
        <div id="breakpoint-2" />
        <div
          className="max-w mobile-shift row-c-c"
          style={{ margin: "0 auto" }}
        >
          <div id="id_2" className="img col-c-c" style={{ width: 300 }}>
            <img
              src="/stack_layer1.png"
              style={{
                marginTop: (coords.offset - offset.id_2) / 25,
                zIndex: 4,
              }}
            />
            <img
              src="/stack_layer2.png"
              style={{
                marginTop: (coords.offset - offset.id_2) / 25 - 150,
                zIndex: 3,
              }}
            />
            <img
              src="/stack_layer3.png"
              style={{
                marginTop: (coords.offset - offset.id_2) / 25 - 150,
                zIndex: 2,
              }}
            />
            <img
              src="/stack_layer4.png"
              style={{
                marginTop: (coords.offset - offset.id_2) / 25 - 100,
                zIndex: 1,
              }}
            />
          </div>
          <div className="landing-textblurb2 max-h pad-64">
            <h2>
              Demonstrate the knowledge you create on Mem
              <span>&mdash;across the web</span>
            </h2>
            <div className="space-v" />
            <div className="space-v" />
            <div className="space-v" />
            <p>
              Create knowledge once, use it everywhere. Mem simplifies the
              creation and curation of high signal information for your
              communities.
            </p>
            <div className="space-v" />
            <div className="space-v" />
            <div className="space-v" />
            <button
              className="gray"
              onClick={() => {
                toggleRunAnimation(!runAnimation);
                push("/community/mem-protocol");
              }}
            >
              Launch App →
            </button>
          </div>
        </div>
        <div style={{ height: 100 }} />
        <div
          className="mobile-shift reverse max-w row-c-c"
          style={{ margin: "0 auto" }}
        >
          <div className="landing-textblurb1 max-h pad-64">
            <h2>
              Communities, raised to the power of <span>you</span>
            </h2>
            <div className="space-v" />
            <div className="space-v" />
            <div className="space-v" />
            <p>
              Make your contributions count. By unifying your experiences across
              DAOs and dApps, keep up with the information, people, and
              communities that matter to you.
            </p>
            <div className="space-v" />
            <div className="space-v" />
            <div className="space-v" />
            <button
              className="gray"
              onClick={() => {
                toggleRunAnimation(!runAnimation);
                push("/community/mem-protocol");
              }}
            >
              Launch App →
            </button>
          </div>
          <div id="id_3" className="img col-c-c" style={{ width: 400 }}>
            <img
              src="/mem_cubes.png"
              style={{
                marginTop: (coords.offset - offset.id_3) / 25,
                zIndex: 4,
              }}
            />
          </div>
        </div>
        <div style={{ height: 200 }} />
        <div
          id="id_4"
          className="landing-feature-grid max-1080 max-w pad-64"
          style={{ marginTop: (coords.offset - offset.id_4) / 25 - 100 }}
        >
          <div>
            <img
              src="grid_3.png"
              style={{
                height: 80,
                marginBottom: 20,
              }}
            />
            <div className="space-v" />
            <h3>Find your most passionate users</h3>
            <div className="space-v" />
            <div className="space-v" />
            <p>
              By asking and answering, your members develop reputation that is
              relevant to the community.
            </p>
          </div>
          <div>
            <div className="pill">Coming soon</div>
            <img
              src="grid_2.png"
              style={{
                height: 80,
                marginBottom: 20,
              }}
            />
            <div className="space-v" />
            <h3>Token-gate asking and answering</h3>
            <div className="space-v" />
            <div className="space-v" />
            <p>
              With Mem, communities can decide who can ask and answer questions
              on specific topics.
            </p>
          </div>
          <div>
            <div className="pill">Coming soon</div>
            <img
              src="grid_1.png"
              style={{
                height: 80,
                marginBottom: 20,
              }}
            />
            <div className="space-v" />
            <h3>Incentivize users with Bounty Multipliers</h3>
            <div className="space-v" />
            <div className="space-v" />
            <p>
              Communities can multiply bounties and incentivize members to
              answer important questions.
            </p>
          </div>
        </div>
        <div className="landing-feature-grid max-1080 max-w pad-64" />
        <div className="space-v show-on-mobile" />
        <div className="space-v show-on-mobile" />
        <div className="space-v show-on-mobile" />
        <div className="landing-investors">
          <div
            className="landing-hover-effect"
            style={{
              top: coords.y - 75,
              left: coords.x - 75,
            }}
          />
          <h1>Investors</h1>
          <div className="space-v" />
          <div className="space-v" />
          <div className="space-v" />
          <div className="space-v" />
          <div className="space-v" />
          <div className="space-v" />
          <div className="landing-investors-grid max-w">
            <div>
              <h4>Andreessen Horowitz</h4>
              <label>INVESTOR</label>
            </div>
            <div>
              <h4>Charlie Cheever</h4>
              <label>ANGEL</label>
            </div>
            <div>
              <h4>Nebula</h4>
              <label>INVESTOR</label>
            </div>
            <div>
              <h4>Olaf Carlson-Wee</h4>
              <label>ANGEL</label>
            </div>
            <div>
              <h4>Balaji Srinivasan</h4>
              <label>ANGEL</label>
            </div>
            <div>
              <h4>Charlie Songhurst</h4>
              <label>ANGEL</label>
            </div>
            <div>
              <h4>Jude Gomila</h4>
              <label>ANGEL</label>
            </div>
            <div>
              <label>And many more...</label>
            </div>
          </div>
        </div>
        <div className="space-v" />
        <div className="space-v" />
        <div className="space-v" />
        <div className="space-v" />
        <div className="space-v" />
        <div className="space-v" />
        <div className="space-v" />
        <div className="space-v" />
        <div className="space-v" />
        <div
          className="landing-card max-1080 col-c-c"
          style={{
            backgroundImage: "url(/join.png)",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            padding: 48,
          }}
        >
          <h2 style={{ textAlign: "center" }}>Bring your community to Mem.</h2>
          <div className="space-v" />
          <div className="space-v" />
          <button className="primary">Get started →</button>
        </div>
        <div className="space-v" />
        <Footer />
      </div>
    </>
  );
}

export const Header = () => {
  const { push }: NextRouter = useRouter();
  const [hamburger, toggleHamburger] = useState<boolean>(false);
  return (
    <div className="landing-header col-c-c max-w" style={{ padding: "0 20px" }}>
      <div className="landing-header-inner max-w center max-1080 row-sb-c">
        <div className="max-h row-fs-c">
          <Link href="/" passHref>
            <img
              alt="logo"
              src="/wordmark.png"
              style={{ cursor: "pointer", width: "112px" }}
            />
          </Link>
          <div className="space-h" />
          <div className="space-h" />
          <div className="space-h" />
          <div className="row-fs-c hide-on-mobile">
            <a
              style={{ textTransform: "uppercase" }}
              className="max-h"
              onClick={() =>
                push("/").then(() =>
                  document
                    ?.getElementById("breakpoint-2")
                    ?.scrollIntoView({ behavior: "smooth" })
                )
              }
            >
              product
            </a>
            <div className="space-h" />
            <div className="space-h" />
            <div className="space-h" />
            <Link href="/team">
              <a style={{ textTransform: "uppercase" }} className="max-h">
                team
              </a>
            </Link>
            <div className="space-h" />
            <div className="space-h" />
            <div className="space-h" />
            <Link href="/blog">
              <a style={{ textTransform: "uppercase" }} className="max-h">
                blog
              </a>
            </Link>
            <div className="space-h" />
            <div className="space-h" />
            <div className="space-h" />
            <Link href="https://workat.mem.co">
              <a style={{ textTransform: "uppercase" }} className="max-h">
                join us
              </a>
            </Link>
          </div>
        </div>
        <button
          className={cn([
            "show-on-mobile hamburgerBtn col-c-c",
            { open: hamburger },
          ])}
          onClick={() => toggleHamburger(!hamburger)}
        >
          <div className="landing-transition" />
          <div className="landing-transition" />
          <div className="landing-transition" />
        </button>
        <div className="max-h row-fs-c hide-on-mobile">
          <button
            onClick={() =>
              push("/").then(() =>
                document
                  ?.getElementById("breakpoint-1")
                  ?.scrollIntoView({ behavior: "smooth" })
              )
            }
          >
            Explore Communities
          </button>
          <div className="space-h" />
          <button
            className="primary"
            onClick={() => push("/community/mem-protocol")}
          >
            Launch App →
          </button>
        </div>
      </div>
      <div
        className={cn([
          "landing-header-mobile show-on-mobile col-c-c landing-transition",
          { open: hamburger },
        ])}
      >
        <a
          className="max-w"
          onClick={() =>
            push("/").then(() =>
              document
                ?.getElementById("breakpoint-2")
                ?.scrollIntoView({ behavior: "smooth" })
            )
          }
        >
          PRODUCT
        </a>
        <div className="space-v" />
        <div className="space-v" />
        <a className="max-w" onClick={() => push("/team")}>
          TEAM
        </a>
        <div className="space-v" />
        <div className="space-v" />
        <a className="max-w" onClick={() => push("/blog")}>
          BLOG
        </a>
        <div className="space-v" />
        <div className="space-v" />
        <button
          className="primary max-w"
          onClick={() => push("/community/mem-protocol")}
        >
          Launch App →
        </button>
      </div>
    </div>
  );
};

export const Footer = () => {
  const { push }: NextRouter = useRouter();
  return (
    <footer className="max-1080 max-w pad-64">
      <div className="row-sb mobile-shift max-w">
        <div className="mobile-shift row">
          <img src="/wordmark.png" style={{ width: 128 }} />
          <div className="space-h" />
          <div className="space-h" />
          <div className="space-h" />
          <div className="space-h" />
          <div className="mobile-shift col">
            <div className="space-v show-on-mobile" />
            <div className="space-v show-on-mobile" />
            <div className="space-v show-on-mobile" />
            <button
              className="primary"
              onClick={() => push("/community/mem-protocol")}
            >
              Launch App →
            </button>
            <div className="space-v" />
            <SignUpForm />
          </div>
        </div>
        <div className="mobile-shift row">
          <div className="mobile-shift col">
            <div className="space-v show-on-mobile" />
            <div className="space-v show-on-mobile" />
            <div className="space-v show-on-mobile" />
            <label>Site Map</label>
            <div className="space-v" />
            <Link href="/community/mem-protocol">Explore</Link>
            <div className="space-v" />
            <Link href="/communities">Communities</Link>
          </div>
          <div className="space-h" />
          <div className="space-h" />
          <div className="space-h" />
          <div className="space-h" />
          <div className="mobile-shift col">
            <div className="space-v show-on-mobile" />
            <div className="space-v show-on-mobile" />
            <div className="space-v show-on-mobile" />
            <label>Socials</label>
            <div className="space-v" />
            <a
              href="https://twitter.com/memprotocol"
              target="_blank"
              rel="noreferrer"
            >
              Twitter
            </a>
            <div className="space-v" />
            <a
              href="https://discord.gg/memprotocol"
              target="_blank"
              rel="noreferrer"
            >
              Discord
            </a>
            <div className="space-v" />
            <Link href="/blog">Blog</Link>
          </div>
        </div>
      </div>
      <div className="space-v" />
      <div className="space-v" />
      <div className="space-v" />
      <div className="space-v" />
      <div className="mobile-shift row-sb max-w">
        <label>© 2021, Mem Protocol Inc.</label>
        <div className="mobile-shift row-fs-c">
          <Link href="/terms">
            <label>Terms of Service</label>
          </Link>
          <div className="space-h" />
          <div className="space-h" />
          <Link href="/privacy">
            <label>Privacy Policy</label>
          </Link>
          <div className="space-h" />
          <div className="space-h" />
        </div>
      </div>
    </footer>
  );
};

const SignUpForm = () => {
  const [signingUp, toggleSigningUp] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [signUpSuccess, toggleSignUpSuccess] = useState<boolean>(false);
  const [signUpError, toggleSignUpError] = useState<boolean>(false);

  useEffect(() => {
    toggleSignUpError(false);
  }, [signUpSuccess]);

  useEffect(() => {
    toggleSignUpSuccess(false);
  }, [signUpError]);

  return signingUp ? (
    <form
      className="row-fs-c mobile-shift"
      onSubmit={async (e) => {
        e.preventDefault();
        toggleSigningUp(false);
        setEmail("");
        return fetch(`/api/signUpForUpdates/${email}`).then(({ status }) =>
          status === 200 ? toggleSignUpSuccess(true) : toggleSignUpError(true)
        );
      }}
    >
      <input
        type="email"
        required
        value={email}
        placeholder="Enter email address"
        onChange={(e) => setEmail(e.currentTarget.value)}
      />
      <div className="space-h" />
      <div className="space-v" />
      <button type="submit" className="gray" style={{ height: 42 }}>
        Sign up →
      </button>
    </form>
  ) : (
    <button
      onClick={() => !signUpSuccess && toggleSigningUp(true)}
      className="gray"
      style={{
        height: 42,
        ...(signUpError ? { background: "red" } : {}),
      }}
    >
      {signUpSuccess
        ? "Success!"
        : signUpError
        ? "Error!"
        : "Sign up for updates"}
    </button>
  );
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  const c = cookies(context);
  if (c["accessToken"]) {
    return {
      redirect: {
        permanent: false,
        destination: "/community/mem-protocol",
      },
    };
  }

  return {
    props: {},
  };
};
